.center-flex {
    justify-content: center;
    display: flex;
}

.bottom-moins-20 {
    margin-bottom: -20px !important;
}

.width-87 {
    width: 83%;
}