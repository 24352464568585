.tag-render-type {
    flex-direction: column !important;
    display: flex;
    align-items: center;
    color: #0A5447;
}

.div-display-package {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.align-self-center {
    align-self: center;
}