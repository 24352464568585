.text-card {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 4px;

    /* grey */

    color: rgb(0, 83, 71);
}