.scroll-div {
    max-height: 250px;
    overflow-y: scroll;
    border: 1px solid rgba(0, 0, 0, 0.06);
}

.scroll-div::-webkit-scrollbar
{
    background-color: white;
    border-radius: 10px;
    width: 20px;
}

.scroll-div::-webkit-scrollbar-thumb
{
    background-color: grey;
    border-radius: 10px;
    border: 5px solid white;
}

.scroll-div::-webkit-scrollbar-thumb:vertical
{
    height: 20px!important;
    width: 20px;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.margin-10 {
    margin: 10px 0px;
}

.text-no-package {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
}

.row {
    display: flex;
    flex-direction: row;
}

.vertical-divider {
    margin: 0px 10px;
    height: 100%;
}

.margin-divider {
    margin: 10px 0px 0px 0px;
}