.align-self-center {
    align-self: center;
}

.flex {
    display: flex;
}

.list-package-panel {
    width: 400px;
}

.row-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.fragile-container-tag {
    background-color: #FFAE4F !important;
    height: 22px;
    align-self: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.text-package {
    height: fit-content;
    align-self: center;
}

.fragile-text-tag {
    color: #1D1D1B;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.01em;
    line-height: 16px;
}

.center-card > div > div > div{
    display: flex;
    justify-content: center;
}

.center-card-package > div > div > div{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.margin-bot-5 {
    margin: 0px 0px 5px 0px !important;
}

.text-card {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 4px;
    color: #717171;
}

.max-width {
    width: 500%;
}

.center-column {
    align-self: center;
}

.no-padding {
    padding: 0px;
}

.number-package {
    background-color: #005347 !important;
    padding: 5px !important;
    color: white !important;
}

.title-home-shop {
    margin-left: 9px;
    color: #005347;
    font-size: 18px;
    margin-bottom: 0px;
}

.select-home {
    width: 95% !important;
    margin-left: 2.75% !important;
    border: 1px solid #005347 !important;
    border-radius: 6px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
/* ci-dessous ajout de style */

.flex-container-col {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    }

.flex-container-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    }


.flex-item{
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    }

